import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Layout } from '@/layouts'
import SEO from '@/components/seo'

const IconLoading = require('@/images/icon_loading2.jpg')

interface Props {}
const NotFound: React.FC<Props> = (): JSX.Element => (
  <Layout>
    <SEO title='404: Not found' description='ページが見つかりません。' />
    <StyledComponent>
      <img src={IconLoading} alt='アイコン' />
      <h1>
        <div>404 Error</div>
        <div>ページが見つかりません</div>
      </h1>
      <Link to='/'>トップに戻る</Link>
    </StyledComponent>
  </Layout>
)
export default NotFound

const StyledComponent = styled.div`
  text-align: center;

  img {
    height: 100px;
  }
  h1 {
    font-size: 32px;
    padding: 16px 0;
  }
`
